<template>
	<div>
		<div class="content-header clearfix">
            <h1 class="float-left">
                {{ $t('pages.warehouse.orderapprovalandpayment') }}
                <small>
                    <slot name="back">
                        <CIcon name="cil-arrow-circle-left"></CIcon>
                        <a href="javascript:void(0)" @click="onCancel">{{ $t('common.back') }}</a>
                    </slot>
                </small>
            </h1>
		</div>
        <CRow>
            <CCol sm="12">
                <CCard>                   
                    <CCardBody v-if="orderDetails != null">
                        <CForm>
                            <div class="row">
                                <div class="col-md-3">
                                    <label>{{ $t('pages.orders.orderNumber') }}</label>
                                </div>
                                <div class="col-md-6">
                                    <p>{{ orderDetails.id }} </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <label>{{ $t('pages.product.numberOfProducts') }}</label>
                                </div>
                                <div class="col-md-6">
                                    <p>{{ orderDetails.numberOfProducts }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <label>{{ $t('pages.product.totalDebtPoints') }}</label>
                                </div>
                                <div class="col-md-6">
                                    <p>{{ $func.formatNumber(orderDetails.debtPoints) }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <label>{{ $t('common.totalAmount') }}</label>
                                </div>
                                <div class="col-md-6">
                                    <p>{{ $func.formatNumber(orderDetails.amount) }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <label>{{ $t('pages.warehouse.paymentMethods') }}</label>
                                </div>
                                <div class="col-md-6">
                                    <select class="form-control" @change="onChangePaymentType($event)">
                                        <option value="-1" selected="selected">{{ $t('pages.warehouse.selectPaymentMethods') }}</option>                                        
                                        <option value="1">{{ $t('pages.bank.bankTransfer') }}</option>
                                        <option value="2">{{ $t('pages.warehouse.cashOnDelivery') }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12" v-if="orderDetails != null && orderDetails.agentOrderItems != null">
                                    <div style="padding:15px">
                                        <h5><label>{{ $t('pages.product.listProductInOrder') }}</label></h5>
                                    </div>
                                    <div>
                                        <CDataTable :items="orderDetails.agentOrderItems"
                                                    :fields="fields"
                                                    :noItemsView="{ noItems: $t('common.nodatafound') }"
                                                    hover
                                                    striped
                                                    border
                                                    small
                                                    fixed
                                                    pagination
                                                    :items-per-page="itemsPerPage"
                                                    @pagination-change="onItemsPerPageChange"
                                                    :items-per-page-select="{
															label : $t('common.recordsperpage'),
															values : [10,20,50,100] }">
                                            <template #footer>
                                                <tfoot>
                                                    <tr>
                                                        <td :colspan="8">
                                                            <span>{{ $t('common.totalRecords') }}: </span><span style="padding-left:5px;color:crimson;font-weight:bold">
                                                                {{orderDetails.agentOrderItems == null ? 0: orderDetails.agentOrderItems.length}}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </template>
                                            <template #product_wholesalePrice="{item}">
                                                <td class="py-2">
                                                    <label v-text="$func.formatNumber(item.price)" />
                                                </td>
                                            </template>
                                            <template #product_quantity="{item}">
                                                <td class="py-2">
                                                    <label v-text="$func.formatNumber(item.quantity)" />
                                                </td>
                                            </template>
                                            <template #product_amount="{item}">
                                                <td class="py-2">
                                                    <label v-text="$func.formatNumber(item.totalAmount)" />
                                                </td>
                                            </template>

                                            <template #debtPoints_text="{item}">
                                                <td class="py-2">
                                                    <label v-text="$func.formatNumber(item.debtPoints)" />
                                                </td>
                                            </template>
                                            <template #totaldebtPoints_text="{item}">
                                                <td class="py-2">
                                                    <label v-text="$func.formatNumber(item.totalDebtPoints)" />
                                                </td>
                                            </template>

                                            <template #finalAmount_test="{item}">
                                                <td class="py-2">
                                                    <label v-text="$func.formatNumber(item.finalAmount)" />
                                                </td>
                                            </template>

                                            <template #InventoryTransfer_InOut="{item, index}">
                                                <td class="py-2">
                                                    <CButton v-if="item.status <= 1" color="primary" variant="outline" size="sm"
                                                             @click="onDeleteWholesaleOrder(item, index)">
                                                        {{ $t('common.delete') }}
                                                    </CButton>
                                                    <CButton v-if="item.status == 2" color="primary" variant="outline" square size="sm"
                                                             @click="onPaymentConfirmation(item, index)">
                                                        {{ $t('common.paymentConfirmation') }}
                                                    </CButton>
                                                    <CButton color="primary" variant="outline" square size="sm">
                                                        {{ $t('common.detail') }}
                                                    </CButton>
                                                </td>
                                            </template>
                                        </CDataTable>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="this.paymentType == 1" style="padding-top:20px">
                                <div class="col-md-12">
                                    <h5>{{ $t('pages.bank.bankaccountinformation') }}</h5>
                                </div>

                                <div class="col-md-12" v-for="(item) in this.distributorBankAccounts" v-bind:key="item.id">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label><b>{{ $t('pages.bank.bankName') }}</b></label>
                                        </div>
                                        <div class="col-md-6">
                                            <p><b>{{  item.bankName }}</b></p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>{{ $t('pages.bank.accountName') }}</label>
                                        </div>
                                        <div class="col-md-6">
                                            <p>{{  item.accountName }}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>{{ $t('pages.bank.accountNo') }}</label>
                                        </div>
                                        <div class="col-md-6">
                                            <p>{{  item.accountNo }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <CButton type="button" color="primary" @click="onApproveDistibutionOrders">{{ this.$t('common.pay') }}</CButton>
                            </div>
                        </CForm>
                    </CCardBody>
                    <CCardBody v-else>
                        <small>{{errorMessage}}</small>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
	</div>
</template>

<script>    
    import { mapGetters } from 'vuex'
	import i18n from '@/plugins/i18n'
   

	export default {
		name: 'Approve',
		data() {
			return {
				horizontal: { label: 'col-3', input: 'col-9' },
                paymentType: -1,
                itemsPerPage: 10,
                fields: [
                    { key: 'productName', label: i18n.t('pages.product.productName') },
                    { key: 'packagingSpecification', label: i18n.t('pages.product.packagingSpecification') },
                    { key: 'product_wholesalePrice', label: i18n.t('pages.product.wholesalePrice') },
                    { key: 'product_quantity', label: i18n.t('pages.product.quantity') },
                    { key: 'product_amount', label: i18n.t('common.amount') },
                    { key: 'debtPoints_text', label: i18n.t('common.debtPoints') },
                    { key: 'totaldebtPoints_text', label: i18n.t('pages.product.totalDebtPoints') },
                    { key: 'finalAmount_test', label: i18n.t('common.totalAmount') },
                ]
			}
		},
		components: {
            
		},
		computed: {
            ...mapGetters('receivedInventory', ['hasError', 'errorMessage', 'orderDetails', 'distributorBankAccounts'])        
		},
        methods: {
           
            onChangePaymentType(event) {
                this.paymentType = parseInt(event.target.value)
			},
            onCancel() {
                this.$router.push('/warehouses/receivelist');
            },
            async onApproveDistibutionOrders() {
				try {

                    if (this.paymentType <= 0) {
                        this.$store.dispatch('alert/error', this.$t('pages.warehouse.selectPaymentMethods'));						
						return;
					}
                    
                    var resp = await this.$store.dispatch("receivedInventory/approveDistibutionOrders", { id: this.$route.params.id, payType: this.paymentType });
                    
                    if (resp.success && resp.data == true) {
                        this.$store.dispatch('toast/displayNotification', { text: i18n.t('common.successfully') });
                        this.$router.push('/warehouses/receivelist');
                    }
                    else if (resp.errorMessage != '') {
                        let mess = resp.errorMessage;
                        if (mess == 'NotEnoughMoney')
                            mess = i18n.t('pages.warehouse.notEnoughMoney');
                        else if (mess == 'InvalidAmount')
                            mess = i18n.t('pages.warehouse.invalidAmount');
                        else if (mess == 'SelectPaymentMethods')
                            mess = i18n.t('pages.warehouse.selectPaymentMethods');
                        else 
                            mess = i18n.t('pages.warehouse.noticeApproveFailed');

                        this.$store.dispatch('alert/error', mess );
                    }
                } catch (e) {
                    this.$store.dispatch('alert/error', 'Error');
                }
            },
            onItemsPerPageChange(item) {
                localStorage.setItem('productItemsPerPage', item)
            },
		},
        mounted() {
            this.$store.dispatch("receivedInventory/getDistributionOrderById", this.$route.params.id);

            let productItemsPerPage = parseInt(localStorage.getItem('productItemsPerPage'));
            if (isNaN(productItemsPerPage))
                productItemsPerPage = 10;
            this.itemsPerPage = productItemsPerPage;
		},
        
	}
</script>

<style scoped>
    .btn-primary {
        background-color: #3c8dbc;
        border-color: #367fa9;
    }

        .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
            color: #fff;
            background-color: #0062cc;
            border-color: #005cbf;
        }

    .btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
        color: #fff;
        background-color: #bd2130;
        border-color: #b21f2d;
    }

    a:hover {
        color: #0056b3;
        text-decoration: none;
    }

    a {
        color: #0056b3;
    }

    .content-header h1 {
        font-size: 1.8rem;
        margin: 0;
    }

    .content-header > h1 {
        margin-bottom: 10px;
    }

    .float-left {
        float: left !important;
    }

    .float-right {
        float: right !important;
    }

    .clearfix::after {
        display: block;
        clear: both;
        content: "";
    }

    .content-header > h1 > small {
        color: #0076bb;
        font-weight: 400;
        margin-left: 6px;
        font-size: 65%;
    }

        .content-header > h1 > small i {
            font-size: .8em;
            padding-right: 2px;
        }

    header {
        background: #3c4b64
    }

    h5 {
        font-size: 1.09375rem;
    }

        h5 a {
            color: #fff;
        }

    .mb-0 {
        margin-bottom: 0 !important;
    }

    .float-right button {
        margin-left: 0.2rem;
    }
</style>
